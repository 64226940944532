
window.dataContainerIndexDbCache = {
    db: null,
    init: function () {
        return new Promise((resolve, reject) => {
            if (this.db) {
                resolve(this.db);
                return;
            }
            // Open the database with the custom name "dataContainerIndexDbCache"
            const request = window.indexedDB.open("dataContainerIndexDbCache", 1);
            request.onerror = (event) => {
                reject("Error opening IndexedDB");
            };
            request.onsuccess = (event) => {
                this.db = event.target.result;
                resolve(this.db);
            };
            request.onupgradeneeded = (event) => {
                let db = event.target.result;
                if (!db.objectStoreNames.contains("datacontainer")) {
                    db.createObjectStore("datacontainer");
                }
            };
        });
    },
    setValue: async function (key, datacontainerObject) {
        let db = await this.init();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(["datacontainer"], "readwrite");
            const store = transaction.objectStore("datacontainer");
            const request = store.put(datacontainerObject, key);
            request.onsuccess = () => resolve(true);
            request.onerror = () => reject("Error saving datacontainer");
        });
    },
    getValue: async function (key) {
        let db = await this.init();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(["datacontainer"], "readonly");
            const store = transaction.objectStore("datacontainer");
            const request = store.get(key);
            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
            request.onerror = () => reject("Error retrieving datacontainer");
        });
    },
    clear: async function () {
        let db = await this.init();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(["datacontainer"], "readwrite");
            const store = transaction.objectStore("datacontainer");
            const request = store.clear();
            request.onsuccess = () => resolve(true);
            request.onerror = () => reject("Error clearing datacontainer");
        });
    }
};
